.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.dialogAction {
  position: fixed;
  bottom: 0;
  right: 0;
  background: #EEEEEE;
  width: 100%;
  max-width: 535px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 600px){
.MuiTab-root {
    min-width: 120px !important;
}
}

.widgetContainer{
  /* background: white; */
  background: rgb(238, 243, 246)
}
.widgetBody {
  background: rgb(255, 255, 255);
    border-style: solid;
    border-color: rgb(216, 216, 216);
    border-radius: 4px;
    border-width: 1px;
    box-shadow: rgb(176 176 176 / 16%) 0px 2px 4px, rgb(176 176 176 / 23%) 0px 2px 4px;
}
.widgetHeader {
  font-size: 14px;
    height: 30px;
    line-height: 30px;
    display: block;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    border-bottom: 0.0625rem solid rgba(0,0,0,.1);
}
.widgetButton {
  margin-right: 7px !important;
}
.item, .MyDragHandleClassName{
  /* border: 1px solid; */
}
.modelDialog {
  width: 500px;
}
.widgetFormControl{
    width: 100%;
}
.widgetCloseButton{
    position: absolute !important;
    right: 1px;
    top: 1px;
    color: gray;
}
.widgetNoData{
  display: flex;
  justify-content: center;
  align-items: center;
}
.widgetTitle{
  font-size: 14px !important;
  font-weight: bold !important;
  padding-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widgetSubTitle{
  font-size: 10px !important;

  padding-left: 12px;
  min-width: 250px;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboardTitle {
  padding-left: 12px;
    margin-top: 8px;
    height: 5px;
}
.dashboardHr{
  border: 1px solid #e0e0e0;
}

.parameterkey{
  font-weight: bolder !important;
}
#ghx-remaining-content {
  background: none repeat scroll 0 0 #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  text-align: center;
  margin: 0.5em auto;
  min-width: 16em;
  width: max-content;
  height: 70%;
  color:#14892c;
  padding-right: 10px;
}
.ghx-remaining-value{
  font-size: 8em;
}
.custom-tooltip{
  background-color: white;
  height:70px;
  width:150px;
  border : 1px solid gray;
}
.custom-tooltip > .name{
  padding-top: 1px;
  padding-bottom: 3px;
  text-align: center;
}
.custom-tooltip > .label{

  text-align: center;
}
.ql-editor p > img {
  width:auto;
 }
 
 .ql-editor {
   word-break: break-word;
 }