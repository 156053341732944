.ql-editor {
	min-height: 120px;
	padding-top: 0;
	height: 500px; 
	overflow-y: auto;
}
.widgeteditor {	
	height: 95% 
}
.summary-widget{
	border: 1px solid #dedede;
    margin: 5px;
}
.summary-container {
	display: flex;
	height: 100%;
}
/* .ql-editor[style*="height"] {
  max-height: unset;
} */
/* .ql-editor::-webkit-resizer {
  background-image: url('../../../assets/images/drag.png');
  background-size: 86%;
	background-position: center;
	background-repeat: no-repeat;
} */
