body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiButton-root {
  text-transform: none !important;
}
.badge-pill {
  border-radius: 10rem;
  padding-right: 0.6em;
  padding-left: 0.6em;
  padding: 0.25em 0.4em;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.pull-right {
  float: right
}
.recharts-responsive-container{
  height: 85% !important;
}
.linechart{
  height: 70% !important;
}

